import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
const Nav = () => {
  const navitems = [
    {
      name: "Home",
    },

    {
      name: "Our Services",
    },
    {
      name: "About Us",
    },
    {
      name: "Contact Us",
    },
    {
      name: "Quick Search",
    },
  ];
  return (
    <div className="Navbar mx-2 mx-lg-0 p-3">
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-expand-lg py-0 ">
            <h1 className="brandname">Purple KinKo </h1>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                {navitems.map((item, index) => (
                  <li
                    key={index}
                    className="effect nav-item active pt-2 pt-lg-0"
                  >
                    <span className="me-xl-4">{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Nav;

import logo from "./logo.svg";
import "./App.css";
import Nav from "./components/Nav";
import Navbar from "./components/Navbar/Navbar";
import pencil from "../src/assets/avatar.png";
import { useEffect, useState } from "react";
import { GetAllServices } from "./ApiServices/api";
import SethuNavbar from "./components/SethuNavbar/SethuNavbar";
import stars from "./assets/4stars.svg";
import dropdownDatas from "./states.json";
function App() {
  // const [selectedState, setSelectedState] = useState(dropdownDatas.states[0]);
  // const [selectedCity, setselectedCity] = useState(selectedState.cities[0]);
  // const [selectedArea, setselectedArea] = useState("");

  const [selectedState, setSelectedState] = useState("Tamil Nadu");
  const [selectedCity, setselectedCity] = useState("");
  const [selectedArea, setselectedArea] = useState("");
  const [servicesData, setservicesData] = useState([]);
const [searchbyServices, setsearchbyServices] = useState(null);
const [companyName, setcompanyName] = useState("");
const [contactPersonName, setcontactPersonName] = useState("")
  const dummydata =[
    {
      1:"seo",
      2:"webiste",
      3:"webapp",
      4:"mobileapp",
      5:"billing software"
    }
  ]
  
  function getServicesApi() {

    const urls = 
  (selectedState ? `?state=${selectedState}` : '') +
  (selectedCity ? `&city=${selectedCity}` : '') +
  (selectedArea ? `&area=${selectedArea}` : '')+(searchbyServices?`&servicesOffering=${searchbyServices}`:'')+(companyName?`&name=${companyName}`:"")+(contactPersonName?`&contactPersonName=${contactPersonName}`:'');

    GetAllServices(urls)
      .then((res) => {
        console.log(res.data.lists);
        setservicesData(res.data.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getServicesApi();
  }, [selectedState,selectedCity,selectedArea,searchbyServices,companyName,contactPersonName]);
  
  return (
    <div>
      {/* <Navbar /> */}
      <Nav />
      <div class="container">
        <div class="row mt-4">
          <div class="col">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedState(e.target.value);
                setselectedCity("")
              }}
            >
              <option selected value="">
                Search By State
              </option>
              {dropdownDatas.states.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            </select>
            {/* <div class="btn-group">
              <button
                class="btn btn-secondary btn-lg dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Large button
              </button>
              <ul class="dropdown-menu">
                {dropdownDatas.states.map((item) => (
                  <li
                    class="dropdown-item"
                    onClick={() => setSelectedState(item)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
          <div class="col">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setselectedCity(e.target.value);
                setselectedArea("")
              }}
            >
              <option selected value="">
                Search By City
              </option>
              {dropdownDatas.states
                .filter((item) => item.name === selectedState)
                .map((states) =>
                  states.cities.map((city) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))
                )}
            </select>
            {/* <div class="btn-group">
              <button
                class="btn btn-secondary btn-lg dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Large button
              </button>
              <ul class="dropdown-menu">
                {selectedState.cities.map((item) => (
                  <li
                    class="dropdown-item"
                    onClick={() => setselectedCity(item)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
          <div class="col">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setselectedArea(e.target.value);
              }}
            >
              <option selected value="">
                Search By Area
              </option>
              {dropdownDatas.states
                .filter((item) => item.name === selectedState)
                .map((state) =>
                  state.cities
                    .filter((city) => city.name === selectedCity)
                    .map((city) =>
                      city.areas.map((area) => (
                        <option key={area} value={area}>
                          {area}
                        </option>
                      ))
                    )
                )}

              {/* {dropdownDatas.states
                .filter((item) => item.name === selectedState)
                .map((states) =>
                  states.cities.filter((city) => city.name === selectedCity)
                    .map((area) => area.areas.map((item)=>(<option value={area}>{area}</option>)))
                )} */}
              {/* {selectedCity.areas.map((item) => (
                <option value={item}>{item}</option>
              
                ))} */}
            </select>
            {/* <div class="btn-group">
              <button
                class="btn btn-secondary btn-lg dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Large button
              </button>
              <ul class="dropdown-menu">
                {selectedCity.areas.map((item) => (
                  <li
                    class="dropdown-item"
                    onClick={() => setselectedArea(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
       
        </div>

           {/* new filters */}

         <div className="row mt-4">
<div class="col-4">
            <div class="input-group mb-3">
     <input type="text" class="form-control" value={companyName} onChange={(e)=>setcompanyName(e.target. value)} placeholder="Search By Company Name"/>
          </div>
</div>

<div class="col-4">
            <div class="input-group mb-3">
     <input type="text" class="form-control" value={contactPersonName} onChange={(e)=>setcontactPersonName(e.target.value)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Search By Name"/>
          </div>
</div>

<div class="col-4">
            <div class="input-group mb-3">
     <input type="text" class="form-control" value={searchbyServices} onChange={(e) => {
                setsearchbyServices(e.target.value);
              }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Search By Keywords"/>
          </div>
</div>
</div> 
      </div>
      <div class="container mt-4">
        {servicesData?.map((item,index) => (
          <div class="card cardsss mb-3 ">
            <div class="row g-0">
            <div className="col-md-4">
                <div
                  id={`carouselExampleControls-${index}`}
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src={item.frontImage}
                        className="d-block w-100"
                        alt="Front"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src={item.BackImage}
                        className="d-block w-100"
                        alt="Back"
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target={`#carouselExampleControls-${index}`}
                    data-bs-slide="prev"
                  >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target={`#carouselExampleControls-${index}`}
                    data-bs-slide="next"
                  >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div class="col-md-8">
                <div class="card-body mediumfonts">
                  <h5 class="card-title title">Company Name : {item.name}</h5>
                  
                    
                    <p> Servcies Offered : {item.servicesOffering}</p>
                    
      
                  <p class="card-text">
                  Contact Person Name:{item.contactPersonName}  
                  </p>
                  <p class="card-text">EmailId : {item.emailId}</p><p class="card-text"> Website : {item.website}</p>
                  <p class="card-text">Phone No : {item.phoneNo}</p>
                  <p class="card-text"> Address : {item.address}</p><p class="card-text">{item.area},{item.city},{item.state}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
